<template>
	<div>
		<div v-if="onePagerReportData && !isLoading">
			<h1 class="title">{{ onePagerReportData.First_name }} {{ onePagerReportData.last_Name }}</h1>
			<h2 class="subtitle">
				<translate>Patient's report</translate><small> - {{ formatDate(new Date()) }}</small>
			</h2>

			<div class="columns is-half"></div>

			<div class="columns is-half">
				<div class="column">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="information-outline"></b-icon>
							<translate>Surgery information</translate>
						</h2>
						<b-field horizontal :label="$gettext('Surgery type')" class="moveup-label">{{ onePagerReportData.limb }}</b-field>
						<b-field horizontal :label="$gettext('Surgeon')" class="moveup-label">{{ onePagerReportData.surgeon }}</b-field>
						<b-field horizontal :label="$gettext('Physical Therapist')" class="moveup-label">{{ onePagerReportData.headPhysio }} </b-field>
						<b-field horizontal :label="$gettext('Hospital')" class="moveup-label">{{ onePagerReportData.hospital }}</b-field>
						<b-field horizontal :label="$gettext('Treatment')" class="moveup-label">{{ onePagerReportData.treatment }}</b-field>
					</div>
				</div>
				<div class="column">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="account-outline" size="is-small"></b-icon>
							<translate>Personal information</translate>
						</h2>
						<b-field v-if="onePagerReportData.patient_number" horizontal :label="$gettext('Patient number')">
							{{ onePagerReportData.patient_number }}
						</b-field>
						<b-field horizontal :label="$gettext('Age')">{{ onePagerReportData.age }}</b-field>
						<b-field horizontal :label="$gettext('Gender')">{{ mapGender(onePagerReportData.Gender) }}</b-field>
						<b-field horizontal :label="$gettext('Surgery date')">{{ formatDate(onePagerReportData.surgery_date) }}</b-field>
					</div>
				</div>
			</div>

			<div class="columns is-half">
				<div class="column">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="text-box-outline"></b-icon>
							<translate>Patient-reported outcomes</translate>
						</h2>
						<table class="table is-striped is-fullwidth">
							<thead>
								<tr>
									<th v-translate>Score</th>
									<th v-translate>Pre-op</th>
									<th v-translate>6 W</th>
									<th v-translate>3 M</th>
									<th v-translate>6 M</th>
									<th v-translate>1 Y</th>
									<th v-translate>2 Y</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<b-tooltip
											:label="$gettextInterpolate(`The oxford %{kneehip} score is a joint specific outcome measure tool designed to assess disability in patients undergoing %{kneehip} surgery.`, { kneehip })"
											type="is-light"
											position="is-top"
											multilined
										>
											<span class="is-underlined"><translate>Oxford %{kneehip} score (/48)</translate></span>
										</b-tooltip>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['Oxford_Score_pre'], onePagerReportData['oxford_score_pre_population'])">
										{{ onePagerReportData['Oxford_Score_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['Oxford_Score_6w'], onePagerReportData['oxford_score_6w_population'])">
										{{ onePagerReportData['Oxford_Score_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['Oxford_Score_3m'], onePagerReportData['oxford_score_3m_population'])">
										{{ onePagerReportData['Oxford_Score_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['Oxford_Score_6m'], onePagerReportData['oxford_score_6m_population'])">
										{{ onePagerReportData['Oxford_Score_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['Oxford_Score_1j'], onePagerReportData['oxford_score_1j_population'])">
										{{ onePagerReportData['Oxford_Score_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['Oxford_Score_2j'], onePagerReportData['oxford_score_2j_population'])">
										{{ onePagerReportData['Oxford_Score_2j'] }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip
											:label="$gettext('Measures the physical or mental feature which is regarded as indicating a negative condition, particularly when such a feature is apparent to the patient.')"
											type="is-light"
											position="is-top"
											multilined
										>
											<span class="is-underlined" v-translate>Symptoms (/100)</span></b-tooltip
										>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_symp_pre'], onePagerReportData['symp_pre_population'])">
										{{ onePagerReportData['koos_symp_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_symp_6w'], onePagerReportData['symp_6w_population'])">
										{{ onePagerReportData['koos_symp_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_symp_3m'], onePagerReportData['symp_3m_population'])">
										{{ onePagerReportData['koos_symp_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_symp_6m'], onePagerReportData['symp_6m_population'])">
										{{ onePagerReportData['OOS_symp_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_symp_1j'], onePagerReportData['symp_1j_population'])">
										{{ onePagerReportData['OOS_symp_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_symp_2j'], onePagerReportData['symp_2j_population'])">
										{{ onePagerReportData['OOS_symp_2j'] }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Measures the patient\'s pain intensity.')" type="is-light" position="is-top" multilined><span class="is-underlined" v-translate>Pain Score (/100)</span></b-tooltip>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_pain_pre'], onePagerReportData['pain_pre_population'])">
										{{ onePagerReportData['koos_pain_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_pain_6w'], onePagerReportData['pain_6w_population'])">
										{{ onePagerReportData['koos_pain_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_pain_3m'], onePagerReportData['pain_3m_population'])">
										{{ onePagerReportData['koos_pain_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS _pain_6m'], onePagerReportData['pain_6m_population'])">
										{{ onePagerReportData['OOS _pain_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_pain_1j'], onePagerReportData['pain_1j_population'])">
										{{ onePagerReportData['OOS_pain_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_pain_2j'], onePagerReportData['pain_2j_population'])">
										{{ onePagerReportData['OOS_pain_2j'] }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Refers to patient\'s daily self-care activities and their ability or inability to perform these activities.')" type="is-light" position="is-top" multilined>
											<span class="is-underlined" v-translate>Activities of Daily Life (/100)</span></b-tooltip
										>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_adl_pre'], onePagerReportData['adl_pre_population'])">
										{{ onePagerReportData['koos_adl_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_adl_6w'], onePagerReportData['adl_6w_population'])">
										{{ onePagerReportData['koos_adl_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_adl_3m'], onePagerReportData['adl_3m_population'])">
										{{ onePagerReportData['koos_adl_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_adl_6m'], onePagerReportData['adl_6m_population'])">
										{{ onePagerReportData['OOS_adl_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_adl_1j'], onePagerReportData['adl_1j_population'])">
										{{ onePagerReportData['OOS_adl_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_adl_2j'], onePagerReportData['adl_2j_population'])">
										{{ onePagerReportData['OOS_adl_2j'] }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Measures how healthy, comfortable, and able to participate in or enjoy life events the patient currently is.')" type="is-light" position="is-top" multilined>
											<span class="is-underlined" v-translate> Quality of Life (/100)</span></b-tooltip
										>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_qol_pre'], onePagerReportData['qol_pre_population'])">
										{{ onePagerReportData['koos_qol_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_qol_6w'], onePagerReportData['qol_pre_population'])">
										{{ onePagerReportData['koos_qol_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['koos_qol_3m'], onePagerReportData['qol_pre_population'])">
										{{ onePagerReportData['koos_qol_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_qol_6m'], onePagerReportData['qol_pre_population'])">
										{{ onePagerReportData['OOS_qol_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_qol_1j'], onePagerReportData['qol_pre_population'])">
										{{ onePagerReportData['OOS_qol_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['OOS_qol_2j'], onePagerReportData['qol_pre_population'])">
										{{ onePagerReportData['OOS_qol_2j'] }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Measures the patient\'s awareness of a specific joint in everyday life.')" type="is-light" position="is-top" multilined>
											<span class="is-underlined" v-translate>Forgotten joint score (/100)</span></b-tooltip
										>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['forgotten_pre'], onePagerReportData['forgotten_pre_population'])">
										{{ onePagerReportData['forgotten_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['forgotten_6w'], onePagerReportData['forgotten_6w_population'])">
										{{ onePagerReportData['forgotten_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['forgotten_3m'], onePagerReportData['forgotten_3m_population'])">
										{{ onePagerReportData['forgotten_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['forgotten_6m'], onePagerReportData['forgotten_6m_population'])">
										{{ onePagerReportData['forgotten_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['forgotten_1j'], onePagerReportData['forgotten_1j_population'])">
										{{ onePagerReportData['forgotten_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData['forgotten_2j'], onePagerReportData['forgotten_2j_population'])">
										{{ onePagerReportData['forgotten_2j'] }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip
											:label="$gettext('Combines different aspects of a patient\'s state. If a patient can double its moveUP index, he/she will usually be very satisfied')"
											type="is-light"
											position="is-top"
											multilined
										>
											<span class="is-underlined" v-translate>moveUP index (/100)</span></b-tooltip
										>
									</th>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData[moveupIndex + '_moveup_index_pre'], onePagerReportData[moveupIndex + '_koos_moveup_index_pre_population'])">
										{{ onePagerReportData[moveupIndex + '_moveup_index_pre'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData[moveupIndex + '_moveup_index_6w'], onePagerReportData[moveupIndex + '_koos_moveup_index_6w_population'])">
										{{ onePagerReportData[moveupIndex + '_moveup_index_6w'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData[moveupIndex + '_moveup_index_3m'], onePagerReportData[moveupIndex + '_koos_moveup_index_3m_population'])">
										{{ onePagerReportData[moveupIndex + '_moveup_index_3m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData[moveupIndex + '_moveup_index_6m'], onePagerReportData[moveupIndex + '_koos_moveup_index_6m_population'])">
										{{ onePagerReportData[moveupIndex + '_moveup_index_6m'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData[moveupIndex + '_moveup_index_1j'], onePagerReportData[moveupIndex + '_koos_moveup_index_1j_population'])">
										{{ onePagerReportData[moveupIndex + '_moveup_index_1j'] }}
									</td>
									<td class="has-text-centered" v-bind:class="getScoreColors(onePagerReportData[moveupIndex + '_moveup_index_2j'], onePagerReportData[moveupIndex + '_koos_moveup_index_2j_population'])">
										{{ onePagerReportData[moveupIndex + '_moveup_index_2j'] }}
									</td>
								</tr>
							</tbody>
						</table>
						<div class="notification">
							<span class="is-size-7"
								><p><strong v-translate>What are the colors meaning?</strong></p>
								<p v-translate>
									The colors are calculated using the other moveUP patients data, divided into quartiles.
									<span class="has-text-danger"><b>Red</b> is applyed for the lowest 25%</span>, <span class="has-text-warning">while <b>orange</b> is used for the patients with scores between 25% and the average. </span
									><span class="has-text-info"> <b>Blue</b> is for patients between the average and 75%</span> and <span class="has-text-success"><b>green</b> for the patients above 75%.</span>
								</p></span
							>
						</div>
					</div>
				</div>
				<div class="column">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="trending-up"></b-icon>
							<translate>Clinical milestones</translate>
						</h2>
						<table class="table is-striped is-fullwidth">
							<thead>
								<tr>
									<th>
										<b-icon icon="pill" size="is-small"></b-icon>
										<translate>Drugs (days of use after surgery)</translate>
									</th>
									<th v-translate>Patient</th>
									<th v-translate>moveUP patients</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient reported that they no longer used Paracetamol.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Stopped Paracetamol
											</span></b-tooltip
										>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['Dafalgan_Paracetamol_stop']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['Dafalgan_Paracetamol_stop_population']) }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient reported that they no longer used Tramadol.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Stopped Tramadol
											</span></b-tooltip
										>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['tradonal_50_stop']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['tradonal_50_stop_population']) }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient reported that they no longer needed a NSAID.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Stopped NSAID
											</span></b-tooltip
										>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['nsaid_stop']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['nsaid_stop_population']) }}
									</td>
								</tr>
							</tbody>
							<thead>
								<tr>
									<th class="pt-5">
										<b-icon icon="emoticon-cry-outline" size="is-small"></b-icon>
										<translate>Pain (# of days after surgery)</translate>
									</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient reported that the pain was under control.')" type="is-light" position="is-top" multilined>
											<span class="is-underlined" v-translate>Pain Under Control </span>
										</b-tooltip>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['pain_under_control']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['pain_under_control_population']) }}
									</td>
								</tr>
							</tbody>
							<thead>
								<tr>
									<th class="pt-5">
										<b-icon icon="walk" size="is-small"></b-icon>
										<translate>Activity (# of days after surgery)</translate>
									</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient reported that they no longer used walking aids.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Stopped walking aids
											</span></b-tooltip
										>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['crutches_stop']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['crutches_stop_population']) }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient reported that they started to drive again.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Started Driving
											</span></b-tooltip
										>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['drive_start']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['drive_start_population']) }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('The day when the patient recovered their pre operatory activity levels.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Recover Preop Activity
											</span></b-tooltip
										>
									</th>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['Day_preop_steps_reached']) }}
									</td>
									<td class="has-text-centered">
										{{ process_milestones(onePagerReportData['Day_preop_steps_reached_population']) }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="columns">
				<div class="column is-half">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="spider-web"></b-icon>
							<translate>Patient's scores over time</translate>
						</h2>
						<apexchart type="radar" height="500" :options="radarChartOptions" :series="radarChartSeries"></apexchart>
					</div>
				</div>
				<div class="column is-half">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="text-box-check-outline"></b-icon>
							<translate>Patient's post surgery compliance</translate>
						</h2>
						<table class="table is-striped is-fullwidth">
							<thead>
								<tr>
									<th v-translate>Questionnaire</th>
									<th>
										<translate>Answers received</translate>
										{{ (onePagerReportData['total_requests_for_this_patient_post_surgery'] ? onePagerReportData['total_requests_for_this_patient_post_surgery'] : 0) | round }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Measures the patient\'s ability to report the execution of the excercises proposed by moveUP.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Exercise
											</span></b-tooltip
										>
									</th>
									<td>
										{{ (onePagerReportData['did_exercises'] ? onePagerReportData['did_exercises'] : 0) | round }}
										%
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Measures if the patient synchronized their device whenever this request was made.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Steps
											</span></b-tooltip
										>
									</th>
									<td>
										{{ onePagerReportData['steps_reported_times'] ? percentage(onePagerReportData['steps_reported_times'], onePagerReportData['total_requests_for_this_patient_post_surgery']) : '' }}
									</td>
								</tr>
								<tr>
									<th>
										<b-tooltip :label="$gettext('Measures if the patient reported the level of pain and stiffness every time they were requested to do so.')" type="is-light" position="is-top" multilined
											><span class="is-underlined" v-translate>
												Pain and stiffness
											</span></b-tooltip
										>
									</th>
									<td>
										{{ onePagerReportData['pain_night_reported_times'] ? percentage(onePagerReportData['pain_night_reported_times'], onePagerReportData['total_requests_for_this_patient_post_surgery']) : '' }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="page-beak"></div>

			<div class="columns">
				<div class="column is-half ">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="finance"></b-icon>
							<translate>Evolution of symptoms</translate>
						</h2>
						<apexchart type="boxPlot" height="350" :options="boxPlotOptions[0]" :series="boxPlotSeries[0]"></apexchart>
					</div>
				</div>
				<div class="column is-half ">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="finance"></b-icon>
							<translate>Evolution of pain</translate>
						</h2>
						<apexchart type="boxPlot" height="350" :options="boxPlotOptions[1]" :series="boxPlotSeries[1]"></apexchart>
					</div>
				</div>
			</div>
			<div class="columns is-half">
				<div class="column is-half ">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="finance"></b-icon>
							<translate>Evolution of Activities of Daily Life</translate>
						</h2>
						<apexchart type="boxPlot" height="350" :options="boxPlotOptions[2]" :series="boxPlotSeries[2]"></apexchart>
					</div>
				</div>
				<div class="column is-half ">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="finance"></b-icon>
							<translate>Evolution of Quality of Life</translate>
						</h2>
						<apexchart type="boxPlot" height="350" :options="boxPlotOptions[3]" :series="boxPlotSeries[3]"></apexchart>
					</div>
				</div>
			</div>

			<div class="columns is-half">
				<div class="column is-full">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="finance"></b-icon>
							<translate>Patient's evolution data</translate>
						</h2>
						<apexchart :height="550" :options="evolutionChartOptions" :series="evolutionChartSeries"></apexchart>
					</div>
				</div>
			</div>

			<div class="columns" v-if="(onePagerReportData.planned_tibia !== null && onePagerReportData.planned_tibia !== undefined) || (onePagerReportData.pre_op_gap && onePagerReportData.pre_op_gap.show) || (onePagerReportData.post_op_gap && onePagerReportData.post_op_gap.show)">
				<div class="column is-half" v-if="onePagerReportData.planned_tibia !== null && onePagerReportData.planned_tibia !== undefined">
					<div class="box">
						<h2 class="subtitle is-4">
							<b-icon icon="text-box-outline"></b-icon>
							<translate>Surgical datapoints</translate>
						</h2>
						<table class="table is-striped is-fullwidth is-small">
							<thead>
								<tr>
									<th v-translate>Parameter</th>
									<th v-translate class="has-text-centered">Planned</th>
									<th v-translate class="has-text-centered">Post-op</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>
										<span><translate>Femur v/v</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Tibia v/v</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_tibia'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_tibia'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>HKA</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_hka'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_hka'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Rotation from TEA</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_rotation_tea'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_rotation_tea'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Rotation from PCA</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_rotation_pca'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_rotation_pca'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Medial Distal Resection</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_medial_distal_resection'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_medial_distal_resection'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Lateral Distal Resection</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_lateral_distal_resection'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_lateral_distal_resection'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Medial Posterior Resection</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_medial_posterior_resection'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_medial_posterior_resection'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Lateral Posterior Resection</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_medial_lateral_resection'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_medial_lateral_resection'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Flexion</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_flexion'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_flexion'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femur Extension</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femur_extension'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femur_extension'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Tibia Slope</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_tibia_slope'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_tibia_slope'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Medial Proximal Resection</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_medial_proximal_resection'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_medial_proximal_resection'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Lateral Proximal Resection</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_lateral_proximal_resection'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_lateral_proximal_resection'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Femoral Size</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_femoral_size'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_femoral_size'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Tibial Size</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_tibial_size'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_tibial_size'] }}</td>
								</tr>
								<tr>
									<th>
										<span><translate>Insert Size</translate></span>
									</th>

									<td class="has-text-centered">{{ onePagerReportData['planned_insert_size'] }}</td>
									<td class="has-text-centered">{{ onePagerReportData['intra_op_insert_size'] }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="column" v-if="onePagerReportData.pre_op_gap && onePagerReportData.pre_op_gap.show">
					<div class="box">
						<div class="is-pulled-right is-flex is-flex-direction-column">
							<span class="tag is-success m-1">Medial</span>
							<span class="tag is-info m-1">Lateral</span>
						</div>
						<h2 class="subtitle is-4">
							<translate>Gap Balancing</translate>
							<p class="is-size-6">pre resection</p>
						</h2>
						<apexchart type="bar" height="500px" :options="dynamicGapBarChartOption" :series="preResectionDynamicGapBarChartSeries"></apexchart>
					</div>
				</div>
				<div class="column" v-if="onePagerReportData.post_op_gap && onePagerReportData.post_op_gap.show">
					<div class="box">
						<div class="is-pulled-right is-flex is-flex-direction-column">
							<span class="tag is-success m-1">Medial</span>
							<span class="tag is-info m-1">Lateral</span>
						</div>
						<h2 class="subtitle is-4">
							<translate>Gap Balancing</translate>
							<p class="is-size-6">post resection</p>
						</h2>
						<apexchart type="bar" height="500px" :options="dynamicGapBarChartOption" :series="postResectionDynamicGapBarChartSeries"></apexchart>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.field-label {
	flex-grow: 2;
	text-align: left;
}

.printTopMargin {
	padding: 200px;
}

.printHeight {
	max-height: 2000px;
}

.box {
	height: 100%;
	background-color: white;
	border-radius: 6px;
	filter: drop-shadow(0 5px 6px rgb(10 10 10 / 10%));
	color: #4a4a4a;
	display: block;
	padding: 1.25rem;
	border: 1px solid #dee2e6 !important;
}

td {
	text-align: center;
	/*filter: drop-shadow(0 10px 12px rgba(0, 0, 0, 0.4));*/
}

.table.is-small td,
th {
	color: #363636;
	padding: 0.2em 0.3em !important;
}
</style>

<script>
import { mapState } from 'vuex';
import { errorToast } from '@/mixins';
import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
	props: { patientId: String, coupleId: String, onePagerReportData: Object },
	data() {
		return {
			isLoading: true,
			kneehip: null,
			moveupIndex: null,
			evolutionChartOptions: {
				chart: {
					id: 'vuechart-example',
				},
				xaxis: {
					categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
				},
			},
			evolutionChartSeries: [],
			boxPlotSeries: [
				[
					{
						name: 'box',
						type: 'boxPlot',
						data: [
							{
								x: new Date('2017-01-01').getTime(),
								y: [54, 66, 69, 75, 88],
							},
							{
								x: new Date('2018-01-01').getTime(),
								y: [43, 65, 69, 76, 81],
							},
							{
								x: new Date('2019-01-01').getTime(),
								y: [31, 39, 45, 51, 59],
							},
							{
								x: new Date('2020-01-01').getTime(),
								y: [39, 46, 55, 65, 71],
							},
							{
								x: new Date('2021-01-01').getTime(),
								y: [29, 31, 35, 39, 44],
							},
						],
					},
					{
						name: 'outliers',
						type: 'scatter',
						data: [
							{
								x: new Date('2017-01-01').getTime(),
								y: 32,
							},
							{
								x: new Date('2018-01-01').getTime(),
								y: 25,
							},
							{
								x: new Date('2019-01-01').getTime(),
								y: 64,
							},
							{
								x: new Date('2020-01-01').getTime(),
								y: 27,
							},
							{
								x: new Date('2020-01-01').getTime(),
								y: 78,
							},
							{
								x: new Date('2021-01-01').getTime(),
								y: 15,
							},
						],
					},
				],
			],
			boxPlotOptions: [
				{
					chart: {
						type: 'boxPlot',
						height: 350,
						toolbar: { show: false },
					},
					colors: ['#008FFB', '#FEB019'],
					xaxis: {
						type: 'datetime',
						tooltip: {
							formatter: function(val) {
								return new Date(val).getFullYear();
							},
						},
					},
					tooltip: {
						shared: false,
						intersect: true,
					},
				},
			],
			radarChartSeries: [],
			radarChartOptions: {
				chart: {
					height: 500,
					type: 'radar',
					dropShadow: {
						enabled: true,
						blur: 1,
						left: 1,
						top: 1,
					},
				},
				tooltip: {
					y: {
						formatter: function(val) {
							return val;
						},
					},
				},
				dataLabels: {
					enabled: true,
				},
				xaxis: {
					//categories: ["Preop", "6 weeks","3 months","6 months","1 year","2 years"]
					categories: [this.$gettext('Symptom'), this.$gettext('Pain'), this.$gettext('Activities of Daily Life'), this.$gettext('Quality of Life'), this.$gettext('Oxford Score'), this.$gettext('Forgotten Joint Score')],
				},
				colors: ['#99E2B4', '#78C6A3', '#56AB91', '#358F80', '#248277', '#036666'],
			},
			dynamicGapBarChartOption: {
				chart: {
					type: 'bar',
					/*height: 500,
					width: 300,*/
					toolbar: {
						show: false,
					},
				},
				legend: {
					show: false,
				},
				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				colors: ['#48c78e', '#3e8ed0'],
				stroke: {
					show: true,
					width: 1,
					colors: ['#fff'],
				},
				xaxis: {
					categories: ['-15°', '0°', '15°', '30°', '45°', '60°', '75°', '90°', '105°', '120°', '135°'].reverse(),
					min: -2,
					max: 8,
					labels: {
						formatter: function(value) {
							return Math.round(value);
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					position: 'top',
				},
				yaxis: {
					opposite: true,
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
				},
				grid: {
					row: {
						colors: ['transparent', 'transparent', 'transparent', '#e5e5e5', 'transparent', 'transparent', '#e5e5e5', 'transparent', 'transparent', '#e5e5e5', 'transparent'],
						opacity: 0.5,
					},
					/*					column: {
						colors: ['#f8f8f8', 'transparent'],
					},*/
				},
				annotations: {
					xaxis: [
						{
							x: 0,
							strokeDashArray: 0,
							borderColor: '#626262',
							borderWidth: 1,
							opacity: 1,
						},
					],
				},
			},
			preResectionDynamicGapBarChartSeries: [],
			postResectionDynamicGapBarChartSeries: [],
		};
	},
	computed: {
		...mapState(['user']),
	},
	mixins: [errorToast],
	methods: {
		async loadAsyncData() {
			try {
				if (!this.patientId && !this.coupleId && !this.onePagerReportData) {
					return;
				}
				if (this.onePagerReportData['limb']?.toLowerCase().includes('knee')) {
					this.kneehip = this.$gettext('knee');
					this.moveupIndex = 'koos';
				} else {
					this.kneehip = this.$gettext('hip');
					this.moveupIndex = 'hoos';
				}
				this.radarChartSeries = [
					{
						name: this.$gettext('Preop'),
						data: [
							this.onePagerReportData['koos_symp_pre'],
							this.onePagerReportData['koos_pain_pre'],
							this.onePagerReportData['koos_adl_pre'],
							this.onePagerReportData['koos_qol_pre'],
							this.change_oxford_scale(this.onePagerReportData['Oxford_Score_pre']),
							this.onePagerReportData['forgotten_pre'],
						],
					},
					{
						name: this.$gettext('6 weeks'),
						data: [
							this.onePagerReportData['koos_symp_6w'],
							this.onePagerReportData['koos_pain_6w'],
							this.onePagerReportData['koos_adl_6w'],
							this.onePagerReportData['koos_qol_6w'],
							this.change_oxford_scale(this.onePagerReportData['Oxford_Score_6w']),
							this.onePagerReportData['forgotten_6w'],
						],
					},
					{
						name: this.$gettext('3 months'),
						data: [
							this.onePagerReportData['koos_symp_3m'],
							this.onePagerReportData['koos_pain_3m'],
							this.onePagerReportData['koos_adl_3m'],
							this.onePagerReportData['koos_qol_3m'],
							this.change_oxford_scale(this.onePagerReportData['Oxford_Score_3m']),
							this.onePagerReportData['forgotten_3m'],
						],
					},
					{
						name: this.$gettext('6 months'),
						data: [
							this.onePagerReportData['OOS_symp_6m'],
							this.onePagerReportData['OOS _pain_6m'],
							this.onePagerReportData['OOS_adl_6m'],
							this.onePagerReportData['OOS_qol_6m'],
							this.change_oxford_scale(this.onePagerReportData['Oxford_Score_6m']),
							this.onePagerReportData['forgotten_6m'],
						],
					},
					{
						name: this.$gettext('1 year'),
						data: [
							this.onePagerReportData['OOS_symp_1j'],
							this.onePagerReportData['OOS_pain_1j'],
							this.onePagerReportData['OOS_adl_1j'],
							this.onePagerReportData['OOS_qol_1j'],
							this.change_oxford_scale(this.onePagerReportData['Oxford_Score_1j']),
							this.onePagerReportData['forgotten_1j'],
						],
					},
					{
						name: this.$gettext('2 years'),
						data: [
							this.onePagerReportData['OOS_symp_2j'],
							this.onePagerReportData['OOS_pain_2j'],
							this.onePagerReportData['OOS_adl_2j'],
							this.onePagerReportData['OOS_qol_2j'],
							this.change_oxford_scale(this.onePagerReportData['Oxford_Score_2j']),
							this.onePagerReportData['forgotten_2j'],
						],
					},
				];

				const titles = [this.$gettext('Symptom'), this.$gettext('Pain'), this.$gettext('Activities of Daily Life'), this.$gettext('Quality of Life')];
				const patientDataFields = [
					['koos_symp_pre', 'koos_symp_6w', 'koos_symp_3m', 'OOS_symp_6m', 'OOS_symp_1j', 'OOS_symp_2j'],
					['koos_pain_pre', 'koos_pain_6w', 'koos_pain_3m', 'OOS _pain_6m', 'OOS_pain_1j', 'OOS_pain_2j'],
					['koos_adl_pre', 'koos_adl_6w', 'koos_adl_3m', 'OOS_adl_6m', 'OOS_adl_1j', 'OOS_adl_2j'],
					['koos_qol_pre', 'koos_qol_6w', 'koos_qol_3m', 'OOS_qol_6m', 'OOS_qol_1j', 'OOS_qol_2j'],
				];
				const populationDataFields = [
					['symp_pre_population', 'symp_6w_population', 'symp_3m_population', 'symp_6m_population', 'symp_1j_population', 'symp_2j_population'],
					['pain_pre_population', 'pain_6w_population', 'pain_3m_population', 'pain_6m_population', 'pain_1j_population', 'pain_2j_population'],
					['adl_pre_population', 'adl_6w_population', 'adl_3m_population', 'adl_6m_population', 'adl_1j_population', 'adl_2j_population'],
					['qol_pre_population', 'qol_6w_population', 'qol_3m_population', 'qol_6m_population', 'qol_1j_population', 'qol_2j_population'],
				];

				const boxplotColors = [
					['#00bbf9', '#99E2B4', '#78C6A3'],
					['#00bbf9', '#56AB91', '#358F80'],
					['#00bbf9', '#248277', '#036666'],
					['#00bbf9', '#99E2B4', '#67B99A'],
				];

				for (let i = 0; i < titles.length; i++) {
					this.boxPlotSeries[i] = this.generateBoxPlotSeries(this.onePagerReportData, patientDataFields[i], populationDataFields[i], titles[i]);
					this.boxPlotOptions[i] = this.generateBoxPlotOptions(boxplotColors[i]);
				}

				this.evolutionChartSeries = [
					{
						name: this.$gettext('Activity'),
						type: 'column',
						data: this.onePagerReportData['steps'],
					},
					{
						name: this.$gettext('Pain during the day'),
						type: 'line',
						data: this.onePagerReportData['pain_day'],
					},
					{
						name: this.$gettext('Pain during the night'),
						type: 'line',
						data: this.onePagerReportData['pain_night'],
					},
					{
						name: this.$gettext('Pain during the exercises'),
						type: 'line',
						data: this.onePagerReportData['pain_exercises'],
					},
					{
						name: this.$gettext('Stifness'),
						type: 'line',
						data: this.onePagerReportData['stifness'],
					},
				];
				const dayText = this.$gettext('day');
				this.evolutionChartOptions = {
					chart: {
						fontFamily: 'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
						animations: {
							enabled: false,
						},

						height: 550,
						zoom: {
							type: 'y',
							enabled: true,
							autoScaleYaxis: false,
						},
						toolbar: {
							autoSelected: 'pan',
						},
					},
					colors: ['#AEBAB1', '#da4167', '#7293a0', '#45b69c', '#A0A4B8'],
					dataLabels: {
						enabled: false,
					},
					grid: {
						row: {
							//colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
							opacity: 0.5,
						},
					},
					yaxis: [
						{
							title: {
								text: this.$gettext('Number of steps'),
							},
						},
						{
							opposite: true,
							title: {
								text: this.$gettext('Pain and stifness scores'),
							},
						},
					],
					stroke: {
						show: true,
						curve: 'smooth',
						width: 1.5,
					},
					annotations: {
						points: [
							{
								x: this.process_milestones(this.onePagerReportData['Dafalgan_Paracetamol_stop'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['Dafalgan_Paracetamol_stop'])],
								marker: { size: 8 },
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Stopped Paracetamol'),
								},
								image: {
									path: './img/pill.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
							{
								x: this.process_milestones(this.onePagerReportData['tradonal_50_stop'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['tradonal_50_stop'])],
								marker: {
									size: 8,
								},
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Stopped Tramadol'),
								},
								image: {
									path: './img/pill.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
							{
								x: this.process_milestones(this.onePagerReportData['nsaid_stop'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['nsaid_stop'])],
								marker: {
									size: 8,
								},
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Stopped NSAID'),
								},
								image: {
									path: './img/pill.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
							{
								x: this.process_milestones(this.onePagerReportData['pain_under_control'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['pain_under_control'])],
								marker: {
									size: 8,
								},
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Pain under control'),
								},
								image: {
									path: './img/happiness.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
							{
								x: this.process_milestones(this.onePagerReportData['crutches_stop'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['crutches_stop'])],
								marker: {
									size: 8,
								},
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Stopped crutches'),
								},
								image: {
									path: './img/weelchair.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
							{
								x: this.process_milestones(this.onePagerReportData['drive_start'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['drive_start'])],
								marker: {
									size: 8,
								},
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Started driving'),
								},
								image: {
									path: './img/car.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
							{
								x: this.process_milestones(this.onePagerReportData['Day_preop_steps_reached'], 'day ', '', null),
								y: this.onePagerReportData['steps'][this.onePagerReportData['treatment_day'].indexOf(this.onePagerReportData['Day_preop_steps_reached'])],
								marker: {
									size: 8,
								},
								label: {
									orientation: 'vertical',
									textAnchor: 'start',
									style: {
										background: '#000',
										color: '#fff',
									},
									borderColor: '#000',
									text: this.$gettext('Recover Preop Activity'),
								},
								image: {
									path: './img/activity.png',
									width: 20,
									height: 20,
									offsetX: -20,
									offsetY: -5,
								},
							},
						],
						xaxis: [
							{
								x: 'day 0',
								x2: 'day ' + this.preprocess_discharge_days(this.onePagerReportData['discharge_days_after_surgery']),
								borderColor: 'transparent',
								fillColor: '#000',
								strokeDashArray: 10,
								label: {
									borderColor: '#000',
									orientation: 'vertical',
									style: {
										fontSize: '20px',
										background: '#000',
										color: '#fff',
									},
									text: this.$gettext('Surgery'),
								},
							},
						],
					},
					xaxis: {
						categories: this.onePagerReportData['treatment_day'],
						tickAmount: 20,
						labels: {
							formatter: function(value) {
								return dayText + ' ' + value;
							},
						},
					},
				};

				this.preResectionDynamicGapBarChartSeries = [];
				if (this.onePagerReportData.pre_op_gap && this.onePagerReportData.pre_op_gap.medial && Array.isArray(this.onePagerReportData.pre_op_gap.medial)) {
					this.preResectionDynamicGapBarChartSeries.push({
						name: 'Medial',
						data: this.onePagerReportData.pre_op_gap.medial.map(n => (n ? n - 18 : null)).reverse(),
					});
				}
				if (this.onePagerReportData.pre_op_gap && this.onePagerReportData.pre_op_gap.lateral && Array.isArray(this.onePagerReportData.pre_op_gap.lateral)) {
					this.preResectionDynamicGapBarChartSeries.push({
						name: 'Lateral',
						data: this.onePagerReportData.pre_op_gap.lateral.map(n => (n ? n - 18 : null)).reverse(),
					});
				}

				this.postResectionDynamicGapBarChartSeries = [];
				if (this.onePagerReportData.post_op_gap && this.onePagerReportData.post_op_gap.medial && Array.isArray(this.onePagerReportData.post_op_gap.medial)) {
					this.postResectionDynamicGapBarChartSeries.push({
						name: 'Medial',
						data: this.onePagerReportData.post_op_gap.medial.map(n => (n ? n - 18 : null)).reverse(),
					});
				}
				if (this.onePagerReportData.post_op_gap && this.onePagerReportData.post_op_gap.lateral && Array.isArray(this.onePagerReportData.post_op_gap.lateral)) {
					this.postResectionDynamicGapBarChartSeries.push({
						name: 'Lateral',
						data: this.onePagerReportData.post_op_gap.lateral.map(n => (n ? n - 18 : null)).reverse(),
					});
				}

				this.isLoading = false;
			} catch (err) {
				this.errorToast(err);
			}
		},
		getScoreColors(score, quantiles) {
			if (!quantiles) return;
			// Apply colors only to integers
			if (Number.isInteger(score)) {
				if (score <= quantiles[1]) {
					return 'has-text-danger';
				} else if (score > quantiles[1] && score <= quantiles[2]) {
					return 'has-text-warning';
				} else if (score > quantiles[2] && score <= quantiles[3]) {
					return 'has-text-info';
				} else if (score > quantiles[3]) {
					return 'has-text-success';
				}
			}
		},
		mapGender(genderValue) {
			if (genderValue == 0) {
				return this.$gettext('Male');
			} else if (genderValue == 1) {
				return this.$gettext('Female');
			}
		},
		formatDate(date) {
			if (!date) return;
			return dayjs.utc(date).format('DD/MM/YYYY');
		},
		process_milestones(milestone, addStartString = '', addEndString = '', returnNullAs = '') {
			if (milestone == -100) {
				// -100 means that we are still waiting for this outcome to happen
				//return '<i class="fas fa-hourglass-half" style="color:#CED3DC"></i>';
				return '';
			} else if (milestone > 0) {
				if (addEndString != '' || addStartString != '') {
					milestone = String(parseInt(milestone));
					return addStartString + milestone + addEndString;
				} else {
					return parseInt(milestone);
				}
			} else if (milestone == null) {
				// null means that the patient never needed it
				return returnNullAs;
			} else {
				return '-';
			}
		},
		percentage(a, b) {
			if (a == null || b == null) {
				return '';
			}
			return `${Math.min(Math.round((a / b) * 100), 100)} %`;
		},
		preprocess_discharge_days(discharge_days_after_surgery) {
			if (discharge_days_after_surgery == null) {
				return 1;
			} else if (discharge_days_after_surgery > 0) {
				return discharge_days_after_surgery;
			} else {
				return 1;
			}
		},
		change_oxford_scale(oxford_score) {
			return parseInt((oxford_score * 100) / 48);
		},
		generateBoxPlotSeries(onePagerReportData, patientFields, populationFields, title) {
			return [
				{
					name: onePagerReportData['First_name'] + ' ' + onePagerReportData['last_Name'] + "'s " + title + ' ' + this.$gettext('average'),
					type: 'scatter',
					data: [
						{
							x: this.$gettext('Preop'),
							y: onePagerReportData[patientFields[0]],
						},
						{
							x: this.$gettext('6 weeks'),
							y: onePagerReportData[patientFields[1]],
						},
						{
							x: this.$gettext('3 months'),
							y: onePagerReportData[patientFields[2]],
						},
						{
							x: this.$gettext('6 months'),
							y: onePagerReportData[patientFields[3]],
						},
						{
							x: this.$gettext('1 year'),
							y: onePagerReportData[patientFields[4]],
						},
						{
							x: this.$gettext('2 years'),
							y: onePagerReportData[patientFields[5]],
						},
					],
				},
				{
					name: this.$gettext('Other moveUP patients'),
					type: 'boxPlot',
					data: [
						{
							x: this.$gettext('Preop'),
							y: onePagerReportData[populationFields[0]],
						},
						{
							x: this.$gettext('6 weeks'),
							y: onePagerReportData[populationFields[1]],
						},
						{
							x: this.$gettext('3 months'),
							y: onePagerReportData[populationFields[2]],
						},
						{
							x: this.$gettext('6 months'),
							y: onePagerReportData[populationFields[3]],
						},
						{
							x: this.$gettext('1 year'),
							y: onePagerReportData[populationFields[4]],
						},
						{
							x: this.$gettext('2 years'),
							y: onePagerReportData[populationFields[5]],
						},
					],
				},
			];
		},
		generateBoxPlotOptions(colors) {
			return {
				yaxis: {
					max: 100,
				},

				chart: {
					type: 'boxPlot',
					height: 350,
					redrawOnParentResize: true,
					fontFamily: 'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
					toolbar: {
						show: false,
					},
				},

				title: {
					align: 'left',
				},

				colors: [colors[0], colors[1]],

				plotOptions: {
					boxPlot: {
						colors: {
							upper: colors[1],
							lower: colors[2],
						},
					},
				},
			};
		},
	},
	mounted() {
		this.loadAsyncData();
	},
};
</script>
